<script setup lang="ts">
import { Alignment, type ColumnHeader, type DefaultSorting, type RowData, SortDirection, Width } from '@/components/SummarizedContent/types'
import { getPriorityColor, getStatusBorderColor, getStatusColor, getStatusTextColor } from '@/utils/helpers/tasks'
import { Keys, useChecklistStore } from '@/stores/views/Optimization/Checklist'
import { computed } from 'vue'
import SummarizedTable from '@/components/SummarizedTable.vue'
import PriorityIcon from '@/components/TaskList/PriorityIcon.vue'
import { Task } from '@aedifion.io/aedifion-api'
import { useI18n } from 'vue-i18n'

const checklistStore = useChecklistStore()

defineProps<{
  loading: boolean
  rows: RowData<Keys>[]
  defaultSorting?: DefaultSorting
  resetSelection: boolean
}>()
const emit = defineEmits<{
  (e: 'click-task', data: Task): void
  (e: 'sort-triggered', payload: DefaultSorting): void
}>()

const { t } = useI18n()

const headers = computed<ColumnHeader<Keys>[]>(() => [{
  key: 'task',
  text: t('checklist.headers.task_text'),
  tooltip: t('checklist.headers.task_tooltip'),
  width: Width.Grow,
}, {
  key: 'componentinproject',
  sortOptions: [SortDirection.ASC, SortDirection.DESC],
  text: t('checklist.headers.component_text'),
  width: Width.MinContent,
}, {
  align: Alignment.Right,
  key: 'cost.saving.potential',
  text: t('checklist.headers.costs_text'),
  tooltip: t('checklist.headers.costs_tooltip'),
  unit: '€',
  width: Width.MinContent,
}, {
  align: Alignment.Left,
  key: 'start',
  sortOptions: [SortDirection.DESC, SortDirection.ASC],
  text: t('checklist.headers.created'),
  tooltip: t('checklist.headers.created'),
  width: Width.MaxContent,
}, {
  align: Alignment.Left,
  key: 'assignee',
  sortOptions: [SortDirection.DESC, SortDirection.ASC],
  text: t('form_fields.assignee'),
  tooltip: t('form_fields.assignee'),
  width: Width.MaxContent,
},
{
  align: Alignment.Left,
  key: 'status',
  sortOptions: [SortDirection.DESC, SortDirection.ASC],
  text: t('checklist.headers.status_text'),
  tooltip: t('checklist.headers.status_tooltip'),
  width: Width.MaxContent,
}])

function showTask (data: RowData) {
  const task = data?.data?.custom
  emit('click-task', task)
}
</script>

<template>
  <SummarizedTable
    v-if="!loading"
    :rows="rows"
    :headers="headers"
    external-sorting
    selectable
    :default-sorting
    :load-more-callback="checklistStore.getMoreTasks"
    :reset-selection="resetSelection"
    @sort-triggered="emit('sort-triggered', $event)"
    @select-row="showTask"
  >
    <template #cell.task="{row}">
      <div
        :key="row.task.text"
        class="d-flex items-center"
      >
        <div
          :key="row.task.text"
          class="d-flex items-center align-center"
        >
          <PriorityIcon
            :priority="row.task.custom.priority"
            :is-grayed-out="row.isGrayedOut"
            :size="14"
          />
          <span
            :class="[`text-subtitle-2 tw-font-medium d-block ml-3 py-4`, { 'text-neutral-darken2': row?.isGrayedOut },
                     !row?.isGrayedOut && `text-${getPriorityColor(row.task.custom.priority)}-darken2`]"
          >
            {{ row.task.text }}
          </span>
        </div>
      </div>
    </template>
    <template #cell.status="{row}">
      <v-chip
        :color="getStatusColor(row.status.text)"
        :class="['tw-h-[24px] tw-mt-4 tw-capitalize text-subtitle-1 tw-font-medium mr-auto my-auto', getStatusTextColor(row.status.text)]"
        :style="{'border': `1px solid rgb(var(--v-theme-${getStatusBorderColor(row.status.text)})) !important`}"
      >
        {{ t(`checklist.status.${row.status.text}`) }}
      </v-chip>
    </template>
  </SummarizedTable>
  <div
    v-else-if="loading"
    data-testid="summarized-table-skeleton"
    class="mt-n4"
  >
    <v-row
      class="d-flex flex-row justify-start content-start mr-2"
    >
      <v-col
        v-for="(index) in 6"
        :key="index"
        cols="2"
      >
        <v-skeleton-loader
          type="text"
          class="mr-auto bg-transparent"
          width="80%"
        />
      </v-col>
    </v-row>
    <v-sheet
      v-for="i in 20"
      :key="i"
    >
      <v-skeleton-loader
        type="table-row"
        class="px-4 summarized-table__skeleton-loader"
      />
    </v-sheet>
  </div>
</template>

<style lang="sass" scoped>
[data-testid="summarized-table-skeleton"] > .v-sheet
  &:nth-child(n+2)
    border-radius: 0 !important
    border-bottom: 0
  &:nth-child(1)
    border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important
    border-radius: 4px !important
  &:nth-child(2)
    border-top-right-radius: 4px !important
    border-top-left-radius: 4px !important
  &:last-child
    border-radius: 4px
    border-top-right-radius: 0
    border-top-left-radius: 0
    border-bottom-right-radius: 4px !important
    border-bottom-left-radius: 4px !important
    border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1)) !important
</style>
