<script setup lang="ts">
import { computed, PropType } from 'vue'
import ItemSelectorWithFilter from '@/components/ItemSelectorWithFilter.vue'
import type { ShortUser } from '@aedifion.io/aedifion-api'
import { ShortUserWithTitle } from '@/stores/views/Administration'
import { storeToRefs } from 'pinia'
import { useUsersInProjectStore } from '@/stores/views/Optimization/Checklist/usersInProject'

const usersInProjectStore = useUsersInProjectStore()
const { fetchMoreProjectUsers } = usersInProjectStore
const { projectUsers: storeProjectUsers, loadingComponentUsers, search } = storeToRefs(usersInProjectStore)

interface Props {
  isEditMode: boolean,
  isReadOnly?: boolean
}

withDefaults(defineProps<Props>(), {
  isReadOnly: false,
})

const assignee = defineModel({
  default: null,
  type: Object as PropType<ShortUserWithTitle|ShortUser|null>,
})

const projectUsers = computed(() => {
  return storeProjectUsers.value ?? []
})

function addTitleToAssignee (assignee: ShortUser|null): ShortUserWithTitle|null {
  if (assignee === null) {
    return null
  }
  return {
    ...assignee,
    title: (assignee?.firstName && assignee?.lastName) ? `${assignee.firstName} ${assignee.lastName}` : assignee?.email ?? '',
  }
}

function onSelectAssignee (newUserId: number|null) {
  if (newUserId === null) {
    assignee.value = null
    return
  }

  const newAssignee = projectUsers.value?.find(user => user.id === newUserId) as ShortUserWithTitle

  assignee.value = {
    avatar_url: newAssignee.avatar_url,
    company_id: newAssignee.company_id,
    email: newAssignee.email,
    firstName: newAssignee.firstName,
    id: newAssignee.id,
    lastName: newAssignee.lastName,
  }
}
</script>

<template>
  <ItemSelectorWithFilter
    v-model="search"
    data-test-id="assignee"
    item-locale-key="notifications.resources.user"
    :filter-fields="['firstName', 'lastName', 'email']"
    :item-pool="projectUsers"
    :is-edit-mode="isEditMode"
    :load-more-callback="fetchMoreProjectUsers"
    :is-read-only="isReadOnly"
    :selected-item="addTitleToAssignee(assignee)"
    :loading="loadingComponentUsers"
    show-clear-button
    @select-item="onSelectAssignee($event.id)"
    @deselect-item="onSelectAssignee(null)"
  >
    <template #selected-item-icon="{ selectedItem }">
      <v-avatar
        size="24"
        class="mr-4 user-selector-avatar tw-w-fit"
      >
        <v-img
          v-if="selectedItem?.avatar_url"
          width="24"
          :src="selectedItem.avatar_url as string"
          data-testid="assignee-avatar"
        />
        <v-icon
          v-else
          size="24"
          data-testid="assignee-avatar-placeholder"
          class="mb-1"
        >
          fal fa-user-circle
        </v-icon>
      </v-avatar>
    </template>
    <template #inner-search-icon>
      <v-icon
        size="24"
        class="text-neutral-darken3 tw-mr-[10px] tw-mt-[-2px]"
      >
        fal fa-user-circle
      </v-icon>
    </template>
    <template #item-option="{item}">
      <div
        class="d-flex flex-row tw-flex-1 align-center"
      >
        <v-avatar
          size="24"
          class="mr-4 user-selector-avatar"
        >
          <v-img
            v-if="item.avatar_url"
            :src="item.avatar_url as string"
          />
          <v-icon
            v-else
            size="24"
            class="mb-1"
          >
            fal fa-user-circle
          </v-icon>
        </v-avatar>
        <span class="d-block tw-flex-grow font-weight-medium text-body-1">{{ item?.firstName && item?.lastName ? `${item.firstName} ${item.lastName}` : item?.email ?? '' }}</span>
        <span class="d-block tw-text-right text-body-1 text-neutral-darken1">{{ item?.email ?? '' }}</span>
      </div>
    </template>
  </ItemSelectorWithFilter>
</template>
