import { ref } from 'vue'
import { getPdfGenerationState } from '@/services/pdfmonkey'
import { GetDocumentCardDataResponse } from '@/services/pdfmonkey/types'

/**
 * Polls the document generation status until the document is ready.
 * @returns The generated document data including the state.
 */
export function usePollPdfmonkeyDocumentState () {
  const POLL_INTERVAL = 1000
  const ALLOWED_RETRIES = 15
  const documentState = ref<GetDocumentCardDataResponse|null>(null)
  const pollTimer = ref<number|null>(null)
  const retryCount = ref(0)

  /**
   * Polls the document generation status until the document is ready.
   * @param documentId The document ID to poll.
   * @returns The generated document data including the state.
   */
  async function pollDocumentState (documentId: string): Promise<GetDocumentCardDataResponse> {
    return new Promise((resolve, reject) => {
      pollTimer.value = setInterval(
        async () => {
          if (retryCount.value >= ALLOWED_RETRIES) {
            clearInterval(pollTimer.value!)
            reject(new Error('Document generation timed out'))
            return
          }

          try {
            documentState.value = await getPdfGenerationState(documentId)
            if (documentState.value.document_card.status === 'success') {
              clearInterval(pollTimer.value!)
              resolve(documentState.value)
            }
          } catch (error) {
            reject(error)
          } finally {
            retryCount.value++
          }
        },
        POLL_INTERVAL,
      )
    })
  }

  return {
    pollDocumentState,
  }
}
