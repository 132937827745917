
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { ControlsAppStatus, useControlsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { getAllStatusCodesWithMeaning, getControlsAppsPayload, getStatusCodeMeaning, NOT_HIDDEN_INACTIVE_STATUS_CODES, reportGetControlsAppsError } from '@/utils/helpers/controls'
import { useAppStore } from '@/stores/app'
import { useAIControlsAlgorithmsStore } from './algorithms'

export type GlobalControlsStatus = 'running' | 'ready' | 'no-switchable-apps'

const APPS_STATUS_STATES: Record<GlobalControlsStatus, ControlsAppStatus[]> = {
  running: getAllStatusCodesWithMeaning('active', true),
  ready: NOT_HIDDEN_INACTIVE_STATUS_CODES.filter((code) => !getStatusCodeMeaning('disabled', code)),
  'no-switchable-apps': [],
}

export const useAIControlsOverviewStore = defineStore('AIControlsOverview', () => {
  const appStore = useAppStore()
  const controlsApiStore = useControlsApiStore()
  const controlsAlgorithmStore = useAIControlsAlgorithmsStore()

  const showCockpitNudge = ref(true)
  const controlsStatus = ref<GlobalControlsStatus>('no-switchable-apps')
  const loadingSemaphore = ref(0)
  const isLoading = computed(() => loadingSemaphore.value > 0)

  function clear (): void {
    controlsStatus.value = 'no-switchable-apps'
    showCockpitNudge.value = false
    loadingSemaphore.value = 0
    controlsAlgorithmStore.clear()
  }

  async function _fetchCockpitNudgeStatus (): Promise<void> {
    try {
      showCockpitNudge.value = (await controlsApiStore.getControlsApps(getControlsAppsPayload(appStore.projectId, getAllStatusCodesWithMeaning('hidden', false)))).meta.total_items === 0
    } catch (error) {
      reportGetControlsAppsError(error as Error)
    }
  }

  async function _fetchAppsStatus (): Promise<void> {
    try {
      if ((await controlsApiStore.getControlsApps(getControlsAppsPayload(appStore.projectId, APPS_STATUS_STATES.running))).meta.total_items > 0) {
        controlsStatus.value = 'running'
      } else {
        if ((await controlsApiStore.getControlsApps(getControlsAppsPayload(appStore.projectId, APPS_STATUS_STATES.ready))).meta.total_items > 0) {
          controlsStatus.value = 'ready'
        } else {
          controlsStatus.value = 'no-switchable-apps'
        }
      }
    } catch (error) {
      controlsStatus.value = 'no-switchable-apps'
      reportGetControlsAppsError(error as Error)
    }
  }

  async function fetchViewDynamicData (): Promise<void> {
    loadingSemaphore.value++
    await Promise.all([
      _fetchAppsStatus(),
      controlsAlgorithmStore.fetchAlgorithmsActiveAppsCounts(),
    ])
    loadingSemaphore.value--
  }

  async function fetchViewData (): Promise<void> {
    clear()
    loadingSemaphore.value++
    await Promise.all([
      _fetchCockpitNudgeStatus(),
      controlsAlgorithmStore.fetchAlgorithms(),
    ])
    if (showCockpitNudge.value) {
      controlsStatus.value = 'no-switchable-apps'
    } else {
      await fetchViewDynamicData()
    }
    loadingSemaphore.value--
  }

  async function toggleControlsAppsStatus (run: boolean) {
    try {
      await controlsApiStore.postControlsAppsRun({ projectId: appStore.projectId, run })
    } catch (error) {
      const action = run ? i18n.global.t('actions.deactivated') : i18n.global.t('actions.activated')
      showErrorNotification(`${i18n.global.t('notifications.errors.optimization.toggle', { action })}`)
      reportError(error)
    }
  }

  return {
    clear,
    controlsStatus,
    fetchViewData,
    fetchViewDynamicData,
    isLoading,
    showCockpitNudge,
    toggleControlsAppsStatus,
  }
})
