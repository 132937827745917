<script setup lang="ts">
import { CompanyRole, CompanyWithContextUsers } from '@aedifion.io/aedifion-api'
import AvatarEditor from '@/components/AvatarEditor.vue'
import { computed } from 'vue'
import HorizontalCardProperty from '@/components/HorizontalCardProperty.vue'
import { useAdministrationStore } from '@/stores/views/Administration'
import { useEditUser } from '../composables/useEditUser'
import { useI18n } from 'vue-i18n'
import UserEditBtn from '../Users/UserEditBtn.vue'
import UserFormDialog from '@/components/UserFormDialog.vue'

interface Props {
  companyRole: CompanyRole|null
  projectsAccess: string
  user: CompanyWithContextUsers & { isLoading?: boolean }|null,
}

const props = withDefaults(defineProps<Props>(), {
  companyRole: null,
  projectsAccess: '0',
  user: null,
})

// --- definition ---

const { t } = useI18n()

const isAvatarLoading = computed<boolean>(() => {
  return props.user?.isLoading ?? false
})

const user = computed(() => {
  let name: string | null = null

  if (props.user) {
    name = (props.user?.firstName && props.user?.lastName) ? `${props.user.firstName} ${props.user.lastName}` : props.user?.email ?? ''
  }

  /**
   * Todo: !TODO - as soon as the backend is fixed on the GET /company endpoint
   */

  /* let phone: string | null = null
  if (props.user?.contact?.phones?.work) {
    phone = props.user?.contact?.phones?.work
  } else if (props.user?.contact?.phones?.mobile) {
    phone = props.user?.contact?.phones?.mobile
  } else if (props.user?.contact?.phones?.home) {
    phone = props.user?.contact?.phones?.home
  } */

  return {
    avatar: props.user?.avatar_url ?? null,
    email: props.user?.email ?? null,
    name,
    phone: null,
  }
})

const companyRole = computed<string|null>(() => {
  if (props.companyRole !== null) {
    return props.companyRole.name!.charAt(0).toUpperCase() + props.companyRole.name!.slice(1)
  } else {
    return null
  }
})

// #region Edit User
const administrationStore = useAdministrationStore()

const { handleEditUser, updateUser, userBeingEdited, isUserFormDialogOpen, toggleUserFormDialogState } = useEditUser()
// #endregion
</script>

<template>
  <div class="mt-8 mr-6">
    <v-card class="user-card pb-6">
      <AvatarEditor
        :avatar="user.avatar"
        class="ma-6"
        hide-toolbar
        :loading="isAvatarLoading"
      />
      <div class="px-4">
        <div>
          <div class="d-flex justify-space-between align-center">
            <HorizontalCardProperty
              icon="fa:far fa-user"
              :value="user.name"
            />
            <UserEditBtn
              @edit-option-click="handleEditUser(props.user.id)"
            />
          </div>
          <HorizontalCardProperty
            v-if="user.email"
            icon="fa:far fa-envelope"
            :value="user.email"
          />
          <HorizontalCardProperty
            v-if="user.phone"
            icon="fa:far fa-phone-rotary"
            :value="user.phone"
          />
          <HorizontalCardProperty
            v-if="companyRole"
            icon="fa:far fa-briefcase"
            :value="companyRole"
          />
        </div>
        <v-divider class="my-4 mx-5" />
        <HorizontalCardProperty
          data-testid="administration-user-card-projects-count"
          icon="fa:far fa-building"
          :label="t('projects')"
          :value="props.projectsAccess"
        />
      </div>
    </v-card>
    <UserFormDialog
      v-if="isUserFormDialogOpen"
      :value="isUserFormDialogOpen"
      :company-roles="administrationStore.companyRoles"
      :projects="administrationStore.projects"
      :loading="administrationStore.loading"
      :users="administrationStore.users"
      :user="userBeingEdited"
      @update-user="updateUser"
      @change="toggleUserFormDialogState"
    />
  </div>
</template>

<style lang="sass" scoped>
.user-card
  width: 300px
</style>

<i18n locale="de">
{
  "projects": "Projekte"
}
</i18n>
<i18n locale="en">
{
  "projects": "Projects"
}
</i18n>
