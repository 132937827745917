<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { onBeforeRouteUpdate } from 'vue-router'
import { useI18n } from 'vue-i18n'
import AlgorithmShortcode from './AlgorithmShortcode.vue'
import { getAlgorithmShortcode } from '@/utils/helpers/controls'

interface Props {
  algorithm?: string,
  description?: string,
  gradientNr?: number,
  isActive?: boolean,
  isBigTitle?: boolean,
  isDescriptionExpandable?: boolean,
  name?: string,
}

const props = withDefaults(defineProps<Props>(), {})

const isTopBorder = ref(false)
const isBottomBorder = ref(false)
const descriptionElement = ref<HTMLDivElement | null>(null)
const isDescriptionExpanded = ref(false)

const { t } = useI18n()

function getAlgorithmInfo (key: 'name' | 'description') {
  return props[key] || t(`ai_controls.algorithm.${props.algorithm?.toLocaleLowerCase()}.${key}`)
}

const shortcode = computed(() => getAlgorithmShortcode(props.algorithm || ''))

onBeforeRouteUpdate(() => { isDescriptionExpanded.value = false })

const getToggleIcon = computed(() => isDescriptionExpanded.value ? 'fa:far fa-chevron-up' : 'fa:far fa-chevron-down')

function toggleDescription () {
  isDescriptionExpanded.value = !isDescriptionExpanded.value
  setBorders()
}

watch(isDescriptionExpanded, () => {
  if (descriptionElement.value) {
    descriptionElement.value.scrollTop = 0
  }
})

function setBorders () {
  if (descriptionElement.value) {
    const { scrollTop, scrollHeight, clientHeight } = descriptionElement.value
    isTopBorder.value = scrollTop > 0
    isBottomBorder.value = scrollTop + clientHeight + 1 < scrollHeight // 1 is needed for scaled browser
  }
}
</script>

<template>
  <v-card class="tw-p-6">
    <div
      v-if="!algorithm"
      data-testid="skeleton-loader"
      class="tw-flex tw-flex-col tw-gap-4"
    >
      <v-skeleton-loader
        type="text"
        class="tw-h-7"
      />
      <v-skeleton-loader type="text" />
      <v-skeleton-loader
        type="text"
        class="tw-h-40"
      />
    </div>
    <div
      v-else
      data-testid="algorithm-content"
    >
      <v-card-title class="tw-flex tw-justify-between tw-items-start tw-gap-2 tw-flex-wrap tw-pt-0 tw-px-0">
        <AlgorithmShortcode
          :shortcode="shortcode"
          :gradient-nr="gradientNr"
          :is-active="isActive"
          :data-testid="`shortcode`"
          :class="`test-is-active-${isActive}`"
        />
        <slot name="title-indicator" />
      </v-card-title>
      <v-card-subtitle
        class="tw-px-0 tw-whitespace-normal tw-opacity-100 tw-tracking-[0.2px]"
        :class="isBigTitle ? 'tw-text-2xl tw-font-semibold text-neutral-darken4 tw-capitalize tw-py-2' : 'tw-text-xs text-neutral-darken1 tw-pb-4'"
        data-testid="algorithm-name"
      >
        {{ getAlgorithmInfo('name') }}
      </v-card-subtitle>
      <v-card-text
        class="tw-text-sm text-neutral-darken3 tw-pt-2 tw-px-0 tw-pb-0"
        :class="{ 'tw-min-h-[116px]': !isDescriptionExpandable}"
      >
        <div
          ref="descriptionElement"
          :class="['description',
                   isDescriptionExpanded
                     ? 'tw-max-h-[calc(100vh-500px)] tw-overflow-y-auto'
                     : 'tw-line-clamp-5',
                   {
                     'top-border': isTopBorder,
                     'bottom-border': isBottomBorder,

                   }]"
          @scroll="setBorders"
          v-html="getAlgorithmInfo('description')"
        />
      </v-card-text>
      <v-card-actions
        v-if="isDescriptionExpandable"
        class="py-2 px-0 tw-min-h-10 tw-justify-center"
      >
        <div
          class="tw-w-6 tw-h-6 tw-flex tw-justify-center tw-items-center"
          @click="toggleDescription"
        >
          <v-icon
            class="text-neutral-darken3 description-toggle-icon"
            size="12"
            role="button"
          >
            {{ getToggleIcon }}
          </v-icon>
        </div>
      </v-card-actions>
      <slot name="actions" />
    </div>
  </v-card>
</template>

<style lang="sass" scoped>
:deep(.v-skeleton-loader)
  .v-skeleton-loader__text
    margin: 0
  &.shortcode .v-skeleton-loader__text
    height: 28px
  &.description .v-skeleton-loader__text
    height: 116px

:deep(.description)
  &.tw-overflow-y-auto
    transition: border 0.3s ease
    &.top-border
      border-top: 1px solid rgb(var(--v-theme-neutral-lighten1))
    &.bottom-border
      border-bottom: 1px solid rgb(var(--v-theme-neutral-lighten1))
  p:not(:last-child)
    margin-bottom: 8px !important

:deep(.v-card-actions) .v-btn
  border-color: rgb(var(--v-theme-primary-lighten2)) !important
  .v-btn__content
    @apply tw-leading-5
</style>
