import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { PostProjectPlotViewPayload, PutProjectPlotViewPayload, useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification, showSuccessNotification } from '@/utils/helpers/notifications'
import i18n from '@/i18n'

export const usePlotViewsInProjectStore = defineStore('plotViewsInProject', () => {
  const projectApiStore = useProjectApiStore()
  const loading = ref(false)

  async function postPlotView (payload: PostProjectPlotViewPayload): Promise<string|undefined> {
    loading.value = true

    try {
      const result = await projectApiStore.postProjectPlotView(payload)
      return result.id
    } catch (error) {
      reportError(error)
      return undefined
    } finally {
      loading.value = false
    }
  }

  async function putPlotView (payload: PutProjectPlotViewPayload): Promise<boolean> {
    loading.value = true
    try {
      await projectApiStore.putProjectPlotView(payload)
      showSuccessNotification(i18n.global.t('notifications.success.update', { resource: i18n.global.t('notifications.resources.task') }))
      return true
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.update', { resource: i18n.global.t('notifications.resources.task') })}`)
      reportError(error)
      return false
    } finally {
      loading.value = false
    }
  }

  return {
    postPlotView,
    putPlotView,
    loading,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlotViewsInProjectStore, import.meta.hot))
}
