<script setup lang="ts" generic="T">
import { onBeforeUnmount, onMounted, ref, VNode, watch } from 'vue'
import debounce from 'lodash.debounce'

type NonEmptyArray<T> = [T, ...T[]]

type KeyMapper<T> = (
  item: T,
  column: number,
  row: number,
  index: number,
) => string | number | symbol | undefined

const props = withDefaults(
  defineProps<{
    columnWidth?: number | NonEmptyArray<number> | undefined
    items: T[]
    gap?: number | undefined
    rtl?: boolean | undefined
    ssrColumns?: number | undefined
    scrollContainer?: HTMLElement | null | undefined
    minColumns?: number | undefined
    maxColumns?: number | undefined
    keyMapper?: KeyMapper<T> | undefined
  }>(),
  {
    columnWidth: 400,
    gap: 0,
    minColumns: 1,
    rtl: false,
    scrollContainer: null,
    ssrColumns: 0,
  },
)

defineSlots<{
  default?: (props: {
    item: T
    column: number
    row: number
    index: number
  }) => VNode
}>()

const emits = defineEmits<{
  (event: 'redraw'): void
  (event: 'redrawSkip'): void
}>()

// const attrs = useAttrs() // make attributes accessible in setup script
const itemsClone = ref<T[]>(props.items) // use a ref copy of the items to not manipulate the original array
watch(() => props.items, (newItems) => { itemsClone.value = newItems as T[] }) // watch for changes of the items prop

/**
 * see source code: https://github.com/DerYeger/yeger/blob/main/packages/vue-masonry-wall-core/src/index.ts
 * there is a redraw function with a force parameter
 * async function redraw(force = false)
 * the resize observer doesn't use the force parameter
 * new ResizeObserver(debounce(() => redraw()))
 * a redraw is skipped as long no column counts change
 * this is probably because items should not move between columns when interacting with them (and change their height)
 * but for initial rendering and changes on items heights while loading (showing skeletons) a redraw (and changing the column) is desired
 * so we need to force a redraw
 * assigning a new value to items or rtl is a workaround to force a redraw
 * watch([items, rtl], () => redraw(true)))
 * so we expose a forceRedraw function to be able to force a redraw after loading and items heights are known
 */
const forceRedraw = () => { itemsClone.value = (props.items as T[]).slice() }
defineExpose({ forceRedraw })

// On resize window we need a listener, because then items might change in height and should be able to change the column even if the column count doesn't change.
const debouncedForceRedraw = debounce(forceRedraw, 100)
onMounted(() => { window.addEventListener('resize', debouncedForceRedraw) })
onBeforeUnmount(() => { window.removeEventListener('resize', debouncedForceRedraw) })
</script>

<template>
  <!-- Check for a valid items array. The div wrapper is needed! It behaves differently when the condition is placed at MasonryWall component. -->
  <div v-if="itemsClone?.length">
    <!-- pass all other atrributes (including listeners) and replace items with the local items clone -->
    <MasonryWall
      v-bind="{...props, items: itemsClone}"
      @redraw="emits('redraw')"
      @redraw-skip="emits('redrawSkip')"
    >
      <!-- pass all used slots to the MasonryWall component -->
      <template
        v-for="(_, slot) in $slots"
        #[slot]="scope"
      >
        <!-- provide all used slots -->
        <slot
          v-bind="scope || {}"
          :name="slot"
        />
      </template>
    </MasonryWall>
  </div>
</template>
