<script setup lang="ts">
import { useAIControlsAppStore } from '@/stores/views/AIControls/App/app'
import { getStatusCodeMeanings } from '@/utils/helpers/controls'
import { ControlsAppStatus } from '@aedifion.io/pinia-aedifion-api-stores'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const aiControlsAppStore = useAIControlsAppStore()

type DisplayTag = {
  textKey?: string,
  textLevel?: 'error' | 'info',
}

const DISPLAY_TAGS: Record<number, DisplayTag> = {
  // 1XX

  [ControlsAppStatus.CREATE_FAILED]: { // 101
    textKey: 'creation_failed',
    textLevel: 'error',
  },

  // 2XX

  [ControlsAppStatus.RUN_FAILED]: { // 201
    textKey: 'run_failed',
    textLevel: 'error',
  },

  // 3XX

  [ControlsAppStatus.STOP_FAILED]: { // 301
    textKey: 'stop_failed',
    textLevel: 'error',
  },
  [ControlsAppStatus.STOPPED_MAINTENANCE]: { // 303
    textKey: 'maintenance',
    textLevel: 'info',
  },
  [ControlsAppStatus.DIED]: { // 304
    textKey: 'died',
    textLevel: 'error',
  },
  [ControlsAppStatus.STOPPED_PEER_DIED]: { // 307
    textKey: 'connection_lost',
    textLevel: 'error',
  },

  // 4XX

  [ControlsAppStatus.UPDATE_REQUESTED]: { // 400
    textKey: 'updating',
    textLevel: 'info',
  },
  [ControlsAppStatus.UPDATE_FAILED]: { // 401
    textKey: 'update_failed',
    textLevel: 'error',
  },

  // 5XX

  [ControlsAppStatus.DELETE_REQUESTED]: { // 500
    textKey: 'deleting',
    textLevel: 'info',
  },
  [ControlsAppStatus.DELETE_FAILED]: { // 501
    textKey: 'deletion_failed',
    textLevel: 'error',
  },
  [ControlsAppStatus.DELETE_INITIATED]: { // 503
    textKey: 'deleting',
    textLevel: 'info',
  },
}

const statusCode = computed(() => {
  return aiControlsAppStore.controlsApp?.status.code ?? -1
})

const state = computed(() => {
  return getStatusCodeMeanings(statusCode.value)
})

const displayTag = computed(() => {
  return DISPLAY_TAGS[statusCode.value] ?? {
    textKey: undefined,
    textLevel: undefined,
  }
})

// @ts-expect-error
const tagTextColor = computed(() => `rgb(var(--v-theme-${displayTag.value.textLevel}-darken2))`)
// @ts-expect-error
const tagBackgroundColor = computed(() => `rgb(var(--v-theme-${displayTag.value.textLevel}-lighten4))`)
// @ts-expect-error
const tagBorderColor = computed(() => `rgb(var(--v-theme-${displayTag.value.textLevel}-lighten3))`)

function toggleClicked () {
  aiControlsAppStore.toggleAppRunningState()
}
</script>

<template>
  <div class="tw-flex tw-items-center tw-gap-2">
    <v-chip
      v-if="displayTag.textKey"
      class="app-toggle-button-tag"
      data-testid="app-toggle-button-tag"
      variant="flat"
    >
      {{ t(displayTag.textKey) }}
    </v-chip>
    <v-switch
      class="app-toggle-button-switch"
      color="success"
      density="compact"
      :disabled="state.disabled"
      :indeterminate="state.indeterminate"
      inset
      hide-details
      :loading="state.pending"
      :model-value="state.active"
      readonly
      @click="toggleClicked"
    />
  </div>
</template>

<style scoped lang="sass">
.app-toggle-button-tag
  height: auto
  padding: 3px 8px

  font-size: 0.75rem
  font-weight: 500
  line-height: 1rem

  color: v-bind(tagTextColor)
  background-color: v-bind(tagBackgroundColor)
  border: solid 1px v-bind(tagBorderColor)

.app-toggle-button-switch
  :deep(.v-selection-control)
    min-height: 24px
    .v-selection-control__wrapper
      height: 24px
      .v-switch__track
        opacity: 1
        background-color: rgb(var(--v-theme-neutral-darken2))
        height: 16px
        min-width: 26px
      .v-selection-control__input
        transform: translateX(-4px)
        height: 24px
        width: 24px
        .v-switch__thumb
          height: 12px
          width: 12px
          box-shadow: 0px 2px 0px 0px rgb(0,0,0,0.2)
          .v-switch__loader .v-progress-circular
            width: 8px !important
            height: 8px !important
    &.v-selection-control--dirty .v-selection-control__input
      transform: translateX(4px)
 </style>

<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="de">
{
  "creation_failed": "Erstellung fehlgeschlagen",
  "run_failed": "Ausführung fehlgeschlagen",
  "stop_failed": "Stoppen fehlgeschlagen",
  "maintenance": "Wartung",
  "died": "Abgestürzt",
  "connection_lost": "Verbindung verloren",
  "updating": "Aktualisierung",
  "update_failed": "Aktualisierung fehlgeschlagen",
  "deleting": "Löschen",
  "deletion_failed": "Löschen fehlgeschlagen"
}
</i18n>
<!-- eslint-disable @intlify/vue-i18n/no-unused-keys -->
<i18n lang="json" locale="en">
{
  "creation_failed": "Creation failed",
  "run_failed": "Run failed",
  "stop_failed": "Stop failed",
  "maintenance": "Maintenance",
  "died": "Died",
  "connection_lost": "Connection lost",
  "updating": "Updating",
  "update_failed": "Update failed",
  "deleting": "Deleting",
  "deletion_failed": "Deletion failed"
}
</i18n>
