<script setup lang="ts">
interface Props {
  shortcode: string
  gradientNr: number
  isActive: boolean
  isOverlayTitle?: boolean
}
defineProps<Props>()

const random = Math.random() * 100
// @ts-expect-error - this is used in the style section only
const styleVars = {
  position25: random < 50 ? 'bottom' : 'top',
  position75: random < 50 ? 'top' : 'bottom',
  startPosition: `${random}%`,
}
</script>

<template>
  <div
    class="shortcode tw-uppercase tw-leading-none tw-font-extrabold tw-bg-clip-text tw-text-transparent tw-bg-cover tw-tracking-[-1.12px] tw-pr-px"
    :class="{ 'active': isActive, 'tw-grayscale tw-opacity-60': !isActive, 'tw-text-[28px]': !isOverlayTitle, 'tw-text-xl': isOverlayTitle }"
    :style="`background-image: url(/img/mesh-gradients/mesh-gradient-${gradientNr}-compressed.png)`"
    data-testId="shortcode"
  >
    {{ shortcode }}
  </div>
</template>

<style lang="sass" scoped>
@keyframes animation
  from
    background-position-y: v-bind('styleVars.startPosition')
  25%
    background-position-y: v-bind('styleVars.position25')
  75%
    background-position-y: v-bind('styleVars.position75')
  to
    background-position-y: v-bind('styleVars.startPosition')

.shortcode
  background-position-y: v-bind('styleVars.startPosition')
  &.active
    animation: animation 10s infinite linear
</style>
