import { CreateDocumentResponse, GetDocumentCardDataResponse } from './types'

const BASE_URL = 'https://api.pdfmonkey.io/api/v1'
const PDF_RESOURCES = {
  checklist: 'CBD7E296-CFE8-4687-BDF4-83A525A45982',
}
const REQUEST_HEADERS = new Headers({
  Authorization: `Bearer ${globalThis.configuration.PDFMONKEY_TOKEN}`,
  'Content-Type': 'application/json',
})

type ResourceId = keyof typeof PDF_RESOURCES

/**
 * Initiates the generation of a PDF document.
 * @param resource The custom resource identifier defined by this application code, to retrieve a PDF report for. Example: 'checklist'.
 * @param payload The payload to be used for generating the PDF document.
 * @returns The response object containing the document ID and the status of the document generation.
 */
export async function initiatePdfGeneration<T> (resource: ResourceId, payload: T): Promise<CreateDocumentResponse<T>> {
  const generatePdfRequest: Request = new Request(new URL(`${BASE_URL}/documents`).toString(), {
    method: 'POST',
    headers: REQUEST_HEADERS,
    mode: 'cors',
    body: JSON.stringify({
      document: {
        ...payload,
        document_template_id: PDF_RESOURCES[resource],
        status: 'pending',
      },
    }),
  })
  const generatePdfResponse: Response = await fetch(generatePdfRequest)

  if (!generatePdfResponse.ok) {
    throw new Error('Error while generating PDF document')
  }

  return await generatePdfResponse.json() as CreateDocumentResponse<T>
}

/**
 * Retrieves the status of a PDF document generation.
 * @param documentId The ID of the document to retrieve the status for.
 * @returns The response object containing the status of the document generation.
 */
export async function getPdfGenerationState (documentId: string): Promise<GetDocumentCardDataResponse> {
  const pdfStatusRequest = new Request(new URL(`${BASE_URL}/document_cards/${documentId}`).toString(), {
    method: 'GET',
    headers: REQUEST_HEADERS,
    mode: 'cors',
  })

  const pdfStatusResponse: Response = await fetch(pdfStatusRequest)

  if (!pdfStatusResponse.ok) {
    throw new Error('Error while generating PDF document')
  }

  return await pdfStatusResponse.json() as GetDocumentCardDataResponse
}
