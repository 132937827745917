<script setup lang="ts">
import { CSSProperties, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { ScheduleItem, TimeSlotTuple } from './ComponentAttributeScheduler.vue'

interface Props {
  scheduleTable: ScheduleItem[]
  areAllPeriodsEmpty: boolean
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'edit-time-slot': [ dayId: number ]
}>()

const { t } = useI18n()

function getPeriodStyle (timeSlot: TimeSlotTuple): CSSProperties {
  if (!timeSlot[0] || !timeSlot[1]) {
    return {}
  }

  const hourHeight = 18.5 // Height for each hour in pixels
  const startHour = parseInt(timeSlot[0].split(':')[0])
  const endHour = parseInt(timeSlot[1].split(':')[0])
  const startMinute = parseInt(timeSlot[0].split(':')[1])
  const endMinute = parseInt(timeSlot[1].split(':')[1])

  const startPosition = (startHour + startMinute / 60 - earliestAndLatestTimeSlots.value.earliestTimeSlot) * hourHeight
  const endPosition = (endHour + endMinute / 60 - earliestAndLatestTimeSlots.value.earliestTimeSlot) * hourHeight
  const height = endPosition - startPosition

  return {
    top: `${startPosition}px`,
    left: '50%',
    transform: 'translate(-50%, 0)',
    height: `${height}px`,
    width: '64px',
    maxWidth: '64px',
    position: 'absolute',
    backgroundColor: 'rgb(var(--v-theme-info-lighten4))',
    borderRadius: '4px',
    border: '1px solid rgb(var(--v-theme-info-lighten3))',
    padding: '4px 15px',
  }
}

const earliestAndLatestTimeSlots = computed(() => {
  const earliestTimeSlot = props.scheduleTable.reduce((earliest, schedule) => {
    const timeSlotStart = schedule.timeSlots?.reduce((start, timeSlot) => {
      const [startHour, startMinute] = timeSlot[0].split(':').map(Number)
      return startHour + startMinute / 60 < start ? startHour + startMinute / 60 : start
    }, 24)
    return timeSlotStart < earliest ? timeSlotStart : earliest
  }, 24)

  const latestTimeSlot = props.scheduleTable.reduce((latest, schedule) => {
    const timeSlotEnd = schedule.timeSlots?.reduce((end, timeSlot) => {
      const [endHour, endMinute] = timeSlot[1].split(':').map(Number)
      return endHour + endMinute / 60 > end ? endHour + endMinute / 60 : end
    }, 0)
    return timeSlotEnd > latest ? timeSlotEnd : latest
  }, 0)

  return {
    earliestTimeSlot,
    latestTimeSlot,
  }
})

const scheduleTableHeight = computed(() => {
  if (props.areAllPeriodsEmpty) {
    return 120
  }

  return (earliestAndLatestTimeSlots.value.latestTimeSlot - earliestAndLatestTimeSlots.value.earliestTimeSlot) * 18.5 + 40
})
</script>

<template>
  <div
    data-testid="schedule-table"
    :class="['tw-flex-grow tw-flex tw-gap-2 tw-p-2 tw-bg-white tw-mt-6 tw-rounded-md  tw-outline tw-outline-1 tw-outline-[rgb(var(--v-theme-neutral-lighten1))]', {
      'tw-flex-grow-0': areAllPeriodsEmpty,
    }]"
    :style="{ height: `${scheduleTableHeight}px` }"
  >
    <div
      v-for="schedule in scheduleTable"
      :key="schedule.id"
      class="tw-flex-grow tw-text-center tw-w-[64px] tw-max-w-[64px]"
    >
      <span class="text-legend text-neutral-darken1 tw-mb-2 tw-block">{{ schedule.day }}</span>
      <div class="tw-h-full tw-relative">
        <template v-if="schedule.timeSlots?.length">
          <template
            v-for="period in schedule.timeSlots"
            :key="`${period}`"
          >
            <v-hover v-slot="{ isHovering, props: periodColHoveringState }">
              <div
                v-bind="periodColHoveringState"
                :style="getPeriodStyle(period)"
                :class="['hover:tw-cursor-pointer', { 'tw-border-[rgb(var(--v-theme-info))]': isHovering }]"
                :data-testid="`schedule-col-${schedule.id}-${period}`"
                @click="emits('edit-time-slot', schedule.id)"
              >
                <v-icon
                  size="14"
                  :class="['tw-absolute tw-top-[50%] text-info-darken2 tw-left-[50%] tw-transform tw--translate-y-1/2 tw--translate-x-1/2 tw-block tw-transition-all tw-ease-in-out tw-duration-500', {
                    'tw-hidden': !isHovering,
                  }]"
                >
                  fa:far fa-pen-clip
                </v-icon>
                <div class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-text-nowrap text-info-darken2">
                  <span
                    :data-testid="`start-time-${schedule.id}-${period}`"
                    class="text-legend"
                  >{{ period[0] }}</span>
                  <span
                    :data-testid="`finish-time-${schedule.id}-${period}`"
                    class="text-legend"
                  >{{ period[1] }}</span>
                </div>
              </div>
            </v-hover>
          </template>
        </template>
        <div v-else>
          <v-hover v-slot="{ isHovering, props: emptyColHoveringProps }">
            <div
              v-bind="emptyColHoveringProps"
              :data-testid="`empty-col-${schedule.id}`"
              :class="['hover:tw-cursor-pointer tw-flex tw-justify-center tw-w-full tw-h-[80px] tw-absolute tw-top-0', { 'no-data-col': isHovering }, {'tw-h-[92.2% tw-h-[calc(100%-24px)]': !areAllPeriodsEmpty }]"
              @click="emits('edit-time-slot', schedule.id)"
            >
              <span
                v-show="!isHovering"
                class="text-legend text-neutral-darken1 tw-my-auto"
                v-text="t('off')"
              />
              <v-icon
                size="14"
                :class="['tw-absolute tw-top-[50%] tw-left-[50%] tw-transform tw--translate-y-1/2 tw--translate-x-1/2 tw-block tw-transition-all tw-ease-in-out tw-duration-500', {
                  'tw-hidden': !isHovering,
                }]"
              >
                fa:far fa-pen-clip
              </v-icon>
            </div>
          </v-hover>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.no-data-col
  background-color: rgb(var(--v-theme-neutral-lighten3))
  border: 1px solid rgb(var(--v-theme-neutral))
  border-radius: 4px
  top: 0
  width: 100%
  max-width: 64px
  position: absolute
  padding: 4px 15px
</style>

<i18n lang="json" locale="de">
{
  "off": "Aus"
}
</i18n>
<i18n lang="json" locale="en">
{
  "off": "Off"
}
</i18n>
