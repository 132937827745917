<script setup lang="ts">
import { computed } from 'vue'
import { ActionMenuItem } from '@/components/ActionMenu.vue'
import type { Attribute } from '@/vuex/component_attributes_editor/types'
import ComponentAttributeBaseRow from './ComponentAttributeBaseRow.vue'
import UnitSelector from './UnitSelector.vue'
import { useI18n } from 'vue-i18n'
import vuexStore from '@/vuex'

interface Props {
  attribute: Attribute
}

const props = defineProps<Props>()

const emits = defineEmits<{
  'set-attribute-error': [ value: Attribute ],
  'update:attribute': [ value: Attribute ]
}>()

const { locale } = useI18n()

const attributeUnit = computed(() => {
  const unit = vuexStore.getters['labels/label']('units', props.attribute?.unit ?? props.attribute?.default_unit)

  if (unit.display_name === 'units') {
    return null
  }

  return unit?.symbol || wrapDisplayName(unit?.display_name)
})

const sisterUnits = computed(() => {
  if (!props.attribute?.unit_type) return []

  return vuexStore.getters['labels/label']('units', props.attribute?.unit_type)
})

const unitList = computed<ActionMenuItem[]>(() => {
  return sisterUnits.value?.children?.map((unit: { id: number; symbol: string, display_name: string }) => ({
    id: unit.id,
    label: unit.symbol || wrapDisplayName(unit?.display_name),
  })) ?? []
})

const handleUpdateUnit = (unit: string) => {
  const unitIdentifier = unitList.value.find((unitItem) => unitItem.label === unit)?.id

  emits('update:attribute', {
    ...props.attribute,
    new_unit: unitIdentifier,
    new_value: props.attribute.value ?? '',
  })
}

const formattedAttribute = computed(() => {
  const formattedValue = props.attribute.value
    ? Intl.NumberFormat(locale.value, {
      maximumFractionDigits: 6,
      minimumFractionDigits: 1,
      notation: 'standard',
    }).format(parseFloat(props.attribute.value))
    : undefined

  return {
    ...props.attribute,
    value: formattedValue,
  }
})

function handleUpdateAttributeValue (newAttributeValue: string) {
  // This means the user has deleted the value
  if (newAttributeValue === '') {
    emits('update:attribute', {
      ...props.attribute,
      new_value: newAttributeValue,
    })
    return
  }

  const unformattedValue = undoFormattingBasedOnLocale(newAttributeValue)

  if (isNaN(unformattedValue)) {
    emits('set-attribute-error', props.attribute)
    return
  }

  emits('update:attribute', {
    ...props.attribute,
    new_value: unformattedValue.toString(),
  })
}

function undoFormattingBasedOnLocale (value: string) {
  if (locale.value === 'de') {
    return parseFloat(value.replace(/\./g, '').replace(/,/, '.'))
  }

  return parseFloat(value.replace(/,/g, ''))
}

function wrapDisplayName (displayName: string|undefined) {
  if (!displayName) return ''

  return `[${displayName}]`
}
</script>

<template>
  <ComponentAttributeBaseRow
    :attribute="formattedAttribute"
    show-suffix
    @update:attribute-value="handleUpdateAttributeValue"
  >
    <template
      v-if="unitList.length && attributeUnit"
      #append-inner
    >
      <UnitSelector
        :default-unit="attributeUnit"
        :unit-list="unitList"
        disabled
        @update-unit="handleUpdateUnit"
      />
    </template>
  </ComponentAttributeBaseRow>
</template>

<style lang="sass" scoped>
:deep(.v-field.v-field--appended)
  padding: 0 !important
</style>
