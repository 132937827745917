import { computed, ref } from 'vue'
import { type CompanyWithContextUsers } from '@aedifion.io/aedifion-api'
import { useAdministrationStore } from '@/stores/views/Administration'
import { useAdministrationUsersStore } from '@/stores/views/Administration/users'

const userIdToDelete = ref<number | null>(null)
const showDeletionDialog = ref(false)

export function useDeleteUser () {
  const administrationStore = useAdministrationStore()
  const administrationUsersStore = useAdministrationUsersStore()

  const userToDelete = computed<CompanyWithContextUsers | null>(() => {
    if (!userIdToDelete.value) {
      return null
    }

    return administrationStore.users.find((user) => {
      return user.id === userIdToDelete.value
    }) as CompanyWithContextUsers
  })

  const userToDeleteFullNameOrEmail = computed<string | null>(() => {
    if (!userToDelete.value) {
      return null
    }

    return (userToDelete.value?.firstName && userToDelete.value?.lastName)
      ? [userToDelete.value.firstName, userToDelete.value.lastName].filter((namePart) => {
        return !!namePart
      }).join(' ')
      : userToDelete.value?.email ?? ''
  })

  function setupUserToDelete (userId: number) {
    showDeletionDialog.value = true
    userIdToDelete.value = userId
  }

  function deleteUser () {
    if (!userToDelete.value) {
      return
    }

    administrationUsersStore.deleteUser(userToDelete.value.id!)
    showDeletionDialog.value = false
    userIdToDelete.value = null
  }

  return {
    deleteUser,
    setupUserToDelete,
    showDeletionDialog,
    userIdToDelete,
    userToDeleteFullNameOrEmail,
  }
}
