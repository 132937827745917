<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAIControlsAppStore } from '@/stores/views/AIControls/App/app'
import AlgorithmShortcode from '../AlgorithmShortcode.vue'
import TrendView from '@/components/TrendView.vue'

interface Props {
  isOpen: boolean
  shortcode: string
  gradientNr: number
  isActive: boolean
  algorithmName: string
}

defineProps<Props>()

const emits = defineEmits<(event: 'close') => void>()

const aiControlsAppStore = useAIControlsAppStore()
const { controlsApp } = storeToRefs(aiControlsAppStore)
</script>

<template>
  <v-dialog
    :model-value="isOpen"
    fullscreen
    @click:outside="emits('close')"
    @keydown.esc.prevent="emits('close')"
    @update:model-value="emits('close')"
  >
    <v-card class="bg-neutral-lighten3 text-neutral-darken3">
      <v-card-title class="tw-px-0 tw-py-0.5 tw-flex tw-justify-between">
        <div class="tw-pl-2 tw-flex tw-gap-2 tw-items-center tw-basis-1/3">
          <AlgorithmShortcode
            :shortcode
            :gradient-nr
            :is-active
            is-overlay-title
          />
          <div
            class="tw-text-sm tw-font-normal"
            data-testid="algorithm-name"
          >
            {{ algorithmName }}
          </div>
        </div>
        <div
          class="tw-text-sm tw-basis-1/3 tw-flex tw-justify-center tw-items-center"
          data-testid="app-name"
        >
          {{ controlsApp.name }}
        </div>
        <div class="tw-basis-1/3 tw-flex tw-flex-row-reverse">
          <v-btn
            color="neutral-darken3"
            icon
            size="x-small"
            variant="text"
            data-testid="close-button"
            @click="emits('close')"
          >
            <v-icon size="12">
              far fa-xmark-large
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <TrendView
        class="tw-overflow-auto tw-flex tw-flex-col tw-justify-between tw-h-full tw-grow"
        data-testid="trend-view"
      />
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
.v-dialog > .v-overlay__content > .v-card
  height: 100%
.trendview
  :deep(.trendview-sheet)
    flex-grow: 1
    display: flex
    flex-direction: column
    .timeseries-viewer
      flex-grow: 1
      display: flex
      flex-direction: column
      .chart
        flex-grow: 1
</style>
