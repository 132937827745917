<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { type ActionMenuItem } from '@/components/ActionMenu.vue'
import { computed } from 'vue'
import { copyStringToClipboard } from '@/utils/helpers/clipboard'
import { type DatapointListItemData } from '@/vuex/datapoints/types'
import { DIKebabItemDatapointPayload } from '@/utils/types'
import { InvalidValueError } from '@/utils/helpers/validate'
import KebabMenu from '@/components/KebabMenu.vue'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/vuex'

interface Props {
  itemData: DatapointListItemData
  readOnly?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  readOnly: false,
})

const { t } = useI18n()
const vuexStore = useStore()

const emits = defineEmits<{
  'datapointlistitem:edit-description': [value: any],
  'datapointlistitem:edit-tags': [value: any],
  'datapointlistitem:write-setpoint': [value: DIKebabItemDatapointPayload]
}>()

const hasPendingFavoritesUpdate = computed<boolean>(() => vuexStore.getters['datapoints/datapointsWithPendingFavoritesUpdate'].includes(props.itemData.title))
const kebabEventData = computed<DIKebabItemDatapointPayload>(() => {
  return {
    description: props.itemData.description,
    hashId: props.itemData.hash_id,
    id: props.itemData.id,
    tags: props.itemData.tags,
    title: props.itemData.title,
  }
})

const kebabMenuItems = computed<Array<ActionMenuItem>>(() => {
  if (props.readOnly) {
    return [{
      id: 'copy-name',
      label: t('copy_name') as string,
    }]
  }

  return [{
    id: 'copy-name',
    label: t('copy_name') as string,
  }, {
    id: 'edit-tags',
    label: t('edit_tags') as string,
  }, {
    id: 'edit-description',
    label: t('edit_description') as string,
  }, {
    disabled: !props.itemData.writable,
    id: 'write-setpoint',
    label: t('write_setpoint') as string,
  }]
})

const score = computed<string|null>(() => {
  if (props.itemData.score) {
    return Math.round(props.itemData.score * 100).toString() + '%'
  } else {
    return null
  }
})

const scoreColor = computed<string>(() => {
  if (props.itemData.score) {
    if (props.itemData.score >= 0.5) {
      return 'success-darken2'
    } else {
      return 'warning-darken2'
    }
  } else {
    return 'transparent'
  }
})

function copyNameToClipboard () {
  copyStringToClipboard(props.itemData.title)
}

async function toggleFavoriteDatapoint () {
  try {
    await vuexStore.dispatch('datapoints/toggleDatapointFavorite', props.itemData.title)
  } catch (error) {
    if (error instanceof InvalidValueError) {
      showErrorNotification(`${t('notifications.errors.no_project_selected_with_attempted_action', { action: t('toggle_favorite_datapoint') })}`)
    }
    reportError(error)
  }
}
</script>

<template>
  <div class="d-flex datapoints-list-item">
    <div
      class="tw-flex-1 py-2 px-0"
      data-cy="datapoint-list-item"
    >
      <div
        v-if="itemData.selected"
        class="selected"
        :style="`background-color: ${itemData.seriesColor}`"
      />
      <v-list-item-title
        class="clamp-text-3 font-weight-medium pa-0 mb-1 tw-w-[270px]"
      >
        {{ itemData.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        v-if="itemData.description"
        class="clamp-text-3 text-subtitle-1"
      >
        {{ itemData.description }}
      </v-list-item-subtitle>
    </div>
    <v-list-item-action
      class="ma-0 d-flex flex-row"
    >
      <v-sheet
        v-if="itemData.score"
        class="mt-1 align-self-start d-flex align-center justify-center"
        :color="scoreColor"
        height="45"
        rounded
        width="45"
      >
        <span
          class="text-body-1 font-weight-medium text-neutral-lighten5"
          v-text="score"
        />
      </v-sheet>
      <div
        class="d-flex flex-column mb-auto"
      >
        <v-btn
          icon
          :loading="hasPendingFavoritesUpdate"
          size="small"
          width="28"
          class="bg-transparent"
          height="28"
          @click.stop="toggleFavoriteDatapoint"
        >
          <v-icon
            :color="itemData.isFavorite ? 'orange' : 'neutral-darken2'"
            size="small"
          >
            {{ itemData.isFavorite ? 'fa:fas fa-star' : 'fa:fal fa-star' }}
          </v-icon>
        </v-btn>
        <KebabMenu
          color="neutral-darken2"
          :items="kebabMenuItems"
          width="28"
          height="28"
          plain
          small
          @copy-name-option-click="copyNameToClipboard()"
          @edit-tags-option-click="emits('datapointlistitem:edit-tags', kebabEventData)"
          @edit-description-option-click="emits('datapointlistitem:edit-description', kebabEventData)"
          @write-setpoint-option-click="emits('datapointlistitem:write-setpoint', kebabEventData)"
        />
      </div>
    </v-list-item-action>
  </div>
</template>

<style lang="sass" scoped>
  @keyframes expand-selection-border
    from
      height: 0%
      top: 52.5%
    to
      height: 90%
      top: 5%

  div.selected
    animation-name: expand-selection-border
    animation-duration: 0.3s
    animation-iteration-count: 1
    width: 7px
    height: 90%
    position: absolute
    top: 5%
    bottom: 0
    left: 5px
    border-radius: 3px

  .clamp-text-3
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    white-space: normal !important

  .datapoints-list-item
    padding-right: 8px !important
</style>

<i18n lang="json" locale="de">
  {
    "copy_name": "Namen kopieren",
    "edit_tags": "Tags bearbeiten",
    "edit_description": "Beschreibung bearbeiten",
    "toggle_favorite_datapoint": "um den Favoritenstatus eines Datenpunkts zu ändern",
    "write_setpoint": "Sollwert schreiben"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "copy_name": "Copy name",
    "edit_tags": "Edit tags",
    "edit_description": "Edit description",
    "toggle_favorite_datapoint": "to change the favorite state of a datapoint",
    "write_setpoint": "Write setpoint"
  }
</i18n>
